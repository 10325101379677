body {
  margin: 0;
  padding: 0;
  background-color: #000000;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.open {
  opacity: 1 !important;
  z-index: 1 !important;
}

@media all and (max-width: 820px) {
  .title {
    font-size: 35px !important;
  }
}

a:link,
a:visited,
a:focus {
  color: #fff;
  text-decoration: none;
  -webkit-transition: .5s;
  transition: .5s;
}

a:hover,
a:active {
  text-decoration: none;
  color: #cd5360;
}
